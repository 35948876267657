<template>
  <flex-column-stack class="notifs" :class="{ loader: loading }">
    <loader v-if="loading"></loader>

    <div v-if="!loading && !notifs" class="notif-placeholder">
      {{ $t("Notifs.noNotifs") }}
    </div>

    <div v-for="notif in notifs" :key="notif.id">
      <notif :read="notif.readed" :notif="notif" :img="notif.speaker_photo">
        <div class="notif__text" :class="{ 'grid-notif': notif.type === 1 }">
          <i v-if="notif.type === 1" class="notif-icon icon-fire"></i>
          <div v-html="notif.text"></div>
        </div>
      </notif>
    </div>

    <notif-pagination v-if="!loading" :pagesCount="totalPages" />
  </flex-column-stack>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

import FlexColumnStack from "@/components/event/layouts/room/FlexColumnStack";
import Notif from "@/components/event/room/Notifs/components/Notif";
import NotifPagination from "@/components/event/room/Notifs/components/NotifPagination";
import Loader from "@/components/common/elements/Loader";
import { notifications } from "@/store/modules/store.namespaces";
import {
  CLEAR_READ_NOTIFS,
  SET_UNREAD_NOTIFICATIONS,
} from "@/store/modules/notifications/mutation-types";
import { GET_NOTIFICATIONS } from "@/store/modules/notifications/action-types";

export default {
  components: {
    FlexColumnStack,
    Notif,
    NotifPagination,
    Loader,
  },

  data() {
    return {
      loading: true,
    };
  },

  computed: {
    ...mapState(notifications, {
      notifs: "notifications",
      totalPages: "pagesCount",
      currentPage: "page",
    }),
  },

  created() {
    const notifOpts = { currentPage: 1, pageCount: 20 };
    this.getAllNotifications(notifOpts)
      .then(() => {
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
    this.resetNotificationsCounter(0);
  },

  beforeDestroy() {
    this.resetNotificationsCounter(0);
    this.clearReadNotifications();
  },

  methods: {
    ...mapActions(notifications, {
      getAllNotifications: GET_NOTIFICATIONS,
    }),
    ...mapMutations(notifications, {
      resetNotificationsCounter: SET_UNREAD_NOTIFICATIONS,
      clearReadNotifications: CLEAR_READ_NOTIFS,
    }),
  },
};
</script>

<style lang="less" scoped>
.loader {
  justify-content: center;
}

.notifs {
  margin-top: 12px !important;
}
.notif-placeholder {
  color: #757575;
  margin: 10px 0;
  font-size: 21px;
  text-align: center;
}
.notif-icon {
  color: var(--base-color);
}
.grid-notif {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 5px;
}
</style>
