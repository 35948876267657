<template>
  <div class="notif" :class="{ 'notif-read': read }">
    <div class="notif-issuer">
      <div v-if="urlImg" class="notif-issuer__logo">
        <img :src="urlImg" />
      </div>
      <p class="notif-issuer__title">
        {{ notif.speaker }} {{ $t("Notifs.at") }}
        {{ notif.time | moment("HH:mm") }}
      </p>
    </div>

    <div class="notif-content"><slot></slot></div>
  </div>
</template>

<script>
import { makeUrl } from "@/utils";

export default {
  props: ["read", "notif", "img"],
  computed: {
    urlImg() {
      return this.img ? makeUrl(this.img) : false;
    },
  },
};
</script>

<style lang="less" scoped>
.notif {
  margin: 0 auto;
  width: ~"calc(100% - 20px)";
  border-radius: 8px;
  background-color: #ebfaff;
  margin-bottom: 12px;
  padding: 12px 20px;
  flex-shrink: 0;
  /*position: relative;*/
  &-icon {
    /*position: absolute;*/
    /*top: 12px;*/
    /*left: 15px;*/
    /*color: red;*/
  }
  &-read {
    background-color: #ffffff;
    border: solid 1px var(--base-color);
  }

  &-issuer {
    display: flex;
    /*justify-content: center;*/
    padding-bottom: 12px;

    &__title {
      margin: 0;
      padding: 0;
      font-size: 14px;
      text-align: left;
      color: #c2c2c2;
    }
    &__logo {
      height: 16px;
      width: 16px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 7px;

      & > img {
        display: inline-block;
        height: 100%;
      }
    }
  }

  &-content {
    font-size: 16px;
    color: #353535;
    & > p {
      padding: 0;
      margin: 0;
    }
  }
}

.notif__text {
  overflow: hidden;
  overflow-wrap: break-word;
  margin: 0;
}
</style>
