<template>
  <div v-if="pagesCount > 1" class="pagination">
    <span
      v-for="page in pages"
      :key="page.name"
      class="pagination-page"
      :class="{ 'pagination-page--isDisabled': page.isDisabled }"
      @click="nextPage(page.name)"
    >
      {{ page.name }}
    </span>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { notifications } from "@/store/modules/store.namespaces";
import { GET_NOTIFICATIONS } from "@/store/modules/notifications/action-types";

export default {
  props: ["pagesCount"],
  data() {
    return {
      currentPage: 1,
    };
  },
  methods: {
    nextPage(currentPage) {
      const notifOpt = { currentPage, pageCount: 20 };
      this.getAllNotifications(notifOpt);
      this.currentPage = currentPage;
    },
    ...mapActions(notifications, {
      getAllNotifications: GET_NOTIFICATIONS,
    }),
  },
  computed: {
    pages() {
      let range = [];
      for (let i = 1; i <= this.pagesCount; i++) {
        range.push({ name: i, isDisabled: i !== this.currentPage });
      }

      return range;
    },
  },
};
</script>

<style lang="less" scoped>
.pagination {
  margin-top: 9px;
  padding-bottom: 15px;
  max-width: 1024px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  // background: lightcoral;
  &-page {
    cursor: pointer;
    display: inline-block;
    width: 32px;
    height: 32px;
    line-height: 32px;
    border: 1px solid var(--base-accent-color);
    border-radius: 50%;
    color: var(--base-accent-color);
    transition: all 0.2s;
    &:not(:last-child) {
      margin-right: 9px;
    }
    &--isDisabled {
      color: var(--base-color-lighter);
      border: 1px solid var(--base-color-lighter);
    }
  }
}
</style>
